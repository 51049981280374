let guestNameSetting;
let formFields = [];
let accessCode;
let guestVerificationSettings;
let dataCollectionSettings;

async function streamMessageText(messageElement) {
    const text = messageElement.innerHTML;
    messageElement.innerHTML = '';
    const tokens = text.split(' ');

    for (let i = 0; i < tokens.length; i++) {
        if (i === tokens.length - 1) {
            messageElement.innerHTML += `${tokens[i]}`;
            await timeout(200);
            break;
        }

        messageElement.innerHTML += `${tokens[i]} `;
        scrollToBottom();
        await timeout(100);
    }
}

async function showGate(propertyName, passcode, titleWrapper, mobileGate, dataCollection) {
    guestVerificationSettings = mobileGate;
    dataCollectionSettings = dataCollection;
    if (dataCollectionSettings.enabled) {
        guestNameSetting = dataCollectionSettings.guestNameSetting;
    }
    else {
        guestNameSetting = '';
    }
    accessCode = passcode;

    const messageList = document.getElementById('messageList');
    const mobileGateContainer = document.getElementById('mobileGateContainer');
    mobileGateContainer.prepend(titleWrapper);
    adjustFontSizeToFit(document.querySelector('#dfTitlebar'), document.querySelector('#propertyName'));
    let openingGateMessage = document.createElement('div');
    if (guestVerificationSettings.enabled) {
        openingGateMessage.innerHTML = `Hi! I'm your AI concierge. Before we get started, I need to perform a one-time verification for access and confirm that you are a guest staying at ${propertyName}. <br><br>You can skip this step but then you won't have access to a lot of features like easy check-out and contacting your host.`;        
    }
    else {
        openingGateMessage.innerHTML = `Hi! I'm your AI concierge. Please share your name and email as confirmation that you are a guest staying at ${propertyName}.`;
    }
    openingGateMessage.classList.add("message", "gate-message");

    messageList.appendChild(openingGateMessage);
    await streamMessageText(openingGateMessage);
    
    createGateForm();

    const gate = document.getElementById('gateForm');
    setTimeout(function () {
        gate.style.display = 'block';
        const input = document.getElementById('phoneNumber');
        if (input) {
            intlTelInput(input, {
                utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js",
            });
        }

        // Use JQuery datepicker for calendar UI
        $('.datepicker').datepicker({
            format: 'mm/dd/yyyy',
            autoclose: true,
            clearBtn: true,
            todayBtn: "linked",
            minDate: "-1y",
            maxDate: "+1y"
        });
        
        setTimeout(function () {
            gate.style.opacity = '1';
            scrollToBottom();
        }, 10);          
    }, 10);
}

function validateField(fieldId, value) {
    switch (fieldId) {
        case 'email':
            if (guestNameSetting === 'required') {
                return {
                    isValid: value.length > 0 && /^[A-Za-z0-9._%-]+(\+[A-Za-z0-9._%-]+)?@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(value),
                    message: value.length === 0 ? 'Email Address is required' : 'Email Address is invalid'
                };
            } 
            else {
                return {
                    isValid: /^[A-Za-z0-9._%-]+(\+[A-Za-z0-9._%-]+)?@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(value) || value.length === 0,
                    message: 'Email is invalid'
                };
            }
        case 'phoneNumber':
            return {
                isValid: value.length === 0 || window.intlTelInputGlobals.getInstance(document.getElementById('phoneNumber')).isValidNumber(),
                message: 'Phone Number is invalid'
            };
        case 'phoneNumberModal':
            return {
                isValid: value.length === 0 || window.intlTelInputGlobals.getInstance(document.getElementById('phoneNumberModal')).isValidNumber(),
                message: 'Phone Number is invalid'
            };
        case 'guestName':
            return {
                isValid: guestNameSetting === 'required' ? value.length > 0 : true,
                message: 'Guest Name is required'
            };
        case 'arrivalDate':
        case 'departureDate':
            if (guestVerificationSettings.enabled) {
                return {
                    isValid: value.length > 0,
                    message: `${fieldId.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())} is required`
                };
            }
            else {
                return {
                    isValid: true,
                    message: ''
                };
            }
        default:
            return {
                isValid: true,
                message: ''
            };
    }
}

function updateValidationMessage(element, validation, display = true) {
    const formGroup = element.closest(".form-group");
    if (!validation.isValid && display) {
        formGroup.classList.add("has-error");
        let errorMessageId = element.id + 'ValidationMessage';
        let errorMessage = document.getElementById(errorMessageId);

        if (errorMessage) {
            if (errorMessage.textContent !== validation.message) {
                errorMessage.textContent = validation.message;
            }
            return;
        }
        errorMessage = document.createElement('span');
        errorMessage.classList.add('help-block');
        errorMessage.textContent = validation.message;
        errorMessage.id = errorMessageId;
        formGroup.appendChild(errorMessage);
    } 
    else {
        formGroup.classList.remove("has-error");
        let validationMessageId = element.id + 'ValidationMessage';
        let validationMessage = document.getElementById(validationMessageId);
        
        if (validationMessage) {
            validationMessage.remove();
        }
    }
}

function validateForm() {
    let formValid = true;

    formFields.forEach((fieldId) => {
        const inputElement = document.getElementById(fieldId);
        const value = inputElement.value.trim();
        const validation = validateField(fieldId, value);

        if (!validation.isValid) {
            updateValidationMessage(inputElement, validation);
            formValid = false;
        } else {
            updateValidationMessage(inputElement, { isValid: true });
        }
    });

    return formValid;
}

function handleValidation(event) {
    const inputElement = event.target;
    const display = ['blur', 'change'].includes(event.type);
    const value = inputElement.value.trim();
    const validation = validateField(inputElement.id, value);
    
    updateValidationMessage(inputElement, validation, display);
}

function scrollToBottom() {
    const messageList = document.getElementById('messageList');

    if (messageList.scrollTop === messageList.scrollHeight) {
        return;
    }

    messageList.scrollTop = messageList.scrollHeight;
}

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function createGateForm() {
    // Building the form as an HTML string
    let emailField = '';
    let guestNameField = '';
    let arrivalDateField = '';
    let departureDateField = '';
    let phoneNumberField = '';
    let skipVerification = '';

    if (guestNameSetting === 'required' || guestNameSetting === 'optional') {
        formFields.push('email',"guestName");
        
        emailField = `
            <div class="form-group">
                <label for="email"><span class="margin-right-sml no-wrap">Email Address${guestNameSetting === 'optional' ? " (optional)" : ""}</span><a href="javascript:;" class="no-wrap" onclick="showEmailUsage()">how is it used?</a></label>
                <input type="email" class="form-control" id="email" placeholder="Enter email" onblur="handleValidation(event)" oninput="handleValidation(event)">
            </div>`
        guestNameField = `
            <div class="form-group">
                <label for="guestName">Guest Name${guestNameSetting === 'optional' ? " (optional)" : ""}</label>
                <input type="text" class="form-control" id="guestName" placeholder="Enter guest name" onblur="handleValidation(event)" oninput="handleValidation(event)">
            </div>`;
    }
    if (guestVerificationSettings.enabled) {
        formFields.push('arrivalDate','departureDate', 'phoneNumber');

        arrivalDateField = `
            <div class="form-group">
                <label for="arrivalDate">Arrival Date</label>
                <input readonly="readonly" type="text" class="form-control datepicker" id="arrivalDate" placeholder="Select your arrival date" onchange="handleValidation(event)">
            </div>
        `;

        departureDateField = `
            <div class="form-group">
                <label for="departureDate">Departure Date</label>
                <input readonly="readonly" type="text" class="form-control datepicker" id="departureDate" placeholder="Select your departure date" onchange="handleValidation(event)">
            </div>
        `;

        phoneNumberField = `
            <div class="form-group">
                <label for="phoneNumber"><span class="margin-right-sml no-wrap">Phone Number (optional)</span><a href="javascript:;" class="no-wrap" onclick="showPhoneUsage()">how is it used?</a></label>
                <input type="text" class="form-control" id="phoneNumber" placeholder="Enter phone number" onblur="handleValidation(event)" oninput="handleValidation(event)">
            </div>
        `;

        skipVerification = `
            <button id='submitButton' type='button' class="gate-button" onclick="window.startDialogFlow()">Skip</button>        
        `;
    }

    let gateFormHTML = `
        <div id="gateForm" class="message gate-message">
            <div class="loading">
                <img src="dist/images/ajax-loader.gif" title="loading"/>
            </div>
            <form>
                ${guestNameField}
                ${arrivalDateField}
                ${departureDateField}
                ${emailField}
                ${phoneNumberField}
                <button id='submitButton' type='button' class="gate-button" onclick='openModal()'>Submit</button>
                ${skipVerification}
            </form>
        </div>`;

    document.getElementById('messageList').innerHTML += gateFormHTML;


    phoneInputModal = document.getElementById("phoneNumberModal");
    intlTelInput(phoneInputModal, {
        utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js",
    });
}

function openModal() {   
    if (!validateForm()) {
        return;
    }
    if (formFields.includes('phoneNumber')) {
        const phoneNumber = window.intlTelInputGlobals.getInstance(document.getElementById('phoneNumber')).getNumber();
        if (!phoneNumber) {
            $('#submitModal').modal('show');
        }
        else {
            checkReservation();
        }       
    }
    else {
        checkReservation();
    }
}

function proceedSubmission() {
    const inputElement = document.getElementById('phoneNumberModal');
    const value = inputElement.value.trim();
    const validation = validateField('phoneNumberModal', value);

    if (!validation.isValid) {
        updateValidationMessage(inputElement, validation);
        return;
    } 
    else {
        updateValidationMessage(inputElement, { isValid: true });
    }
    checkReservation(true);
    $('#submitModal').modal('hide');
}

function showEmailUsage() {
    mscAlert('', 'Your email is provided to your host to assist with guest communication, including pre-arrival, conveying any important information during your stay, and post-stay follow up. You always have the option to opt-out of email communication at any point.');
}

function showPhoneUsage() {
    mscAlert('', 'Your phone number is only used for the duration of your stay to send you notifications with important information from your host. It is completely removed following your departure.');
}

function checkReservation(useModalPhone = false) {
    let phoneNumber;
    let modalPhoneNumber;
    if (formFields.includes('phoneNumber')) {
        phoneNumber = window.intlTelInputGlobals.getInstance(document.getElementById('phoneNumber')).getNumber();
        modalPhoneNumber = window.intlTelInputGlobals.getInstance(document.getElementById('phoneNumberModal')).getNumber();
    }
    const collectGuestInfo = ['optional', 'required'].includes(guestNameSetting);

    const data = {
        passcode: accessCode,
        guestEmail: collectGuestInfo ? document.getElementById('email').value : null,
        guestName: collectGuestInfo ? document.getElementById('guestName').value : null,
        guestPhone: useModalPhone ? modalPhoneNumber : phoneNumber
    };

    if (guestVerificationSettings.enabled) {
        data.checkinDate = $('#arrivalDate').datepicker( "getDate" ).toISOString().split('T')[0];
        data.checkoutDate = $('#departureDate').datepicker( "getDate" ).toISOString().split('T')[0];
    }
    
    if (!phoneNumber && modalPhoneNumber) {
        window.intlTelInputGlobals.getInstance(document.getElementById('phoneNumber')).setNumber(modalPhoneNumber);
        window.intlTelInputGlobals.getInstance(document.getElementById('phoneNumberModal')).setNumber('');
    }

    window.postValidation(data);
}

/**
 * Checks if the child element overflows the parent and minimizes the text if true 
 * till the child no longer overflows or becomes smaller than 10px.
 */
function adjustFontSizeToFit(parent, child) {
    try {
        let originalFontSize = parseFloat(window.getComputedStyle(child, null).getPropertyValue('font-size'));
        let currentFontSize = originalFontSize;
        let text = child.innerHTML;

        // Reduce font size until the child no longer overflows the parent
        while (child.offsetWidth > parent.offsetWidth || child.offsetHeight > parent.offsetHeight) {
            currentFontSize--;
            child.style.fontSize = `${currentFontSize}px`;
            
            if (currentFontSize < 13) { // Prevents font size from becoming too small
                break;
            }
        }
        
        if (child.offsetWidth > parent.offsetWidth || child.offsetHeight > parent.offsetHeight) {
            // If still overflowing, reset font size and truncate text
            child.style.fontSize = `${originalFontSize}px`; // Reset to original font size
            let textLength = text.length;
            while (child.offsetWidth > parent.offsetWidth || child.offsetHeight > parent.offsetHeight) {
                textLength--;
                child.innerHTML = text.substring(0, textLength).trim() + '...';
                if (textLength < 13) { // Prevents removing all characters
                    break;
                }
            }
        }
    }
    catch (error) {
        // This Try-Catch ensures that any errors in the resizing logic do not prevent the rest of the code from executing
    }
}